//----------  layout
//------------------------------------------------------------------------------
html, body {
	@include font-smoothing(antialiased);
	height: 100%;
}

body {
	font-family: $font-default;
	font-size: 16px;
	color: $grey;
	background-color: $grey-bg;
	&.overflow-hidden {
		position: relative;
		overflow: hidden;
	}
}

.container {
	@include base-float;
	padding: 0;
	overflow-x: hidden;
	&.overflow-hidden {
		position: relative;
		overflow: hidden;
	}
}

.content {
	@include base-float;
	padding-top: 135px;
	&.hidden {
		display: none;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 100px;
	}
}

.main-content {
	@include base-float;
	position: relative;
	top: -87px;
	@include media-breakpoint-down(md) {
		top: 0;
	}
}

.wrapper {
	max-width: 1170px;
	padding: 0 15px;
	margin: 0 auto;
	@include media-breakpoint-down(md) {
		padding: 0;
	}
}

.white-bg-content {
	background-color: $white;
	min-height: 290px;
	overflow: hidden;
	padding-bottom: 50px;
	margin-bottom: 50px;
}

section {
	@include base-float;
}

//----------  pages with floating content element
//------------------------------------------------------------------------------
.floating {
	@include media-breakpoint-down(md) {
		top: 0;
		.wrapper {
			padding: 0;
		}
	}
}
