//----------  fonts
//------------------------------------------------------------------------------

@font-face {
	font-family:'TBC Din Nusxuri';
	src: url('../fonts/TBC Din Nusxuri Regular.woff') format('woff'),
		url('../fonts/TBC Din Nusxuri Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family:'TBC Din Nusxuri';
	src: url('../fonts/TBCDinNusxuri-Bold.woff') format('woff'),
		url('../fonts/TBCDinNusxuri-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
