//----------  forms
//------------------------------------------------------------------------------
input,
textarea {
	border-radius: 0;
	box-shadow: none;
	background: $white;
	height: 25px;
	padding: 0 10px;
	color: $black;
	@include placeholder {
		color: $grey;
	}
}

.selectric-wrapper {
	max-width: 372px;
	width: 100%;
	margin: 0;
	.selectric {
		border: 2px solid $yellow;
		border-radius: 0;
		z-index: 200;
		padding-right: 40px;
		.label {
			font-size: 16px;
			height: 40px;
			line-height: 38px;
			padding: 0 10px;
			margin: 0;
			color: #5a5a5a;
		}
		.button {
			top: 2px;
			&::after {
				content: '\f107';
				@include icon-font;
			}
		}
	}
	.selectric-items {
		top: 100%;
		box-shadow: none;
		border: 1px solid #cecece;
		border-top: 0;
		z-index: 100;
		li {
			font-size: 16px;
			font-weight: 700;
			margin: 0;
			&:last-of-type {
				border-bottom: 0;
			}
			&:hover {
				background-color: #ededed;;
			}
		}
	}
}
