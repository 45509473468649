//----------  breadcrumb
//------------------------------------------------------------------------------
.breadcrumb {
	@include base-float;
	margin-bottom: 12px;
	li {
		margin-right: 4px;
		padding-left: 0;
		color: $yellow;
		display: inline-block;
	}
	a, span {
		font-size: 16px;
		line-height: 20px;
		font-weight: bold;
		color: $yellow;
		text-transform: uppercase;
		text-decoration: underline;
		margin-right: 5px;
		// letter-spacing: .02em;
	}
	a:hover {
		color: $white;
	}
	.prev {
		text-decoration: none;
		&::before {
			content: $fa-var-chevron-circle-left;
			@include icon-font;
			position: relative;
			color: $white;
		}
	}
	@include media-breakpoint-down(md) {
		margin-bottom: 28px;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 17px;
		li {
			margin-right: 6px;
		}
		a, span {
			font-size: 14px;
			line-height: 15px;
		}
	}
}

//----------  news and calendar list
//------------------------------------------------------------------------------
.news-list,
.calendar-list {
	@include base-float;
	.fleft {
		width: 75%;
		margin-right: 5%;
	}
	.link-list {
		width: 20%;
		float: right;
		.fleft {
			width: 100%;
			margin-right: 0;
		}
	}
	li {
		@include base-float;
		border-bottom: 2px solid $grey-bg;
	}
	.date {
		@include base-float;
		font-size: 14px;
		line-height: 15px;
		font-weight: bold;
		color: $grey;
		text-transform: uppercase;
		margin-bottom: 10px;
		display: block;
	}
	h2 {
		margin-bottom: 14px;
		// letter-spacing: -.02em;
		a {
			color: inherit;
			font-size: inherit;
			line-height: inherit;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	// @include media-breakpoint-down(md) {
	// 	.link-list {
	// 		width: 14%;
	// 		padding-left: 10px;
	// 	}
	// }
	@include media-breakpoint-down(sm) {
		.link-list {
			width: 100%;
		}
	}
}

//----------  news list
//------------------------------------------------------------------------------
.news-list {
	li {
		padding: 30px 0 15px;
	}
	@include media-breakpoint-down(md) {
		// .fleft {
		// 	width: 100%;
		// }
		li {
			padding-bottom: 23px;
		}
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 22px;
		li {
			padding-bottom: 6px;
		}
	}
}

//----------  calendar list
//------------------------------------------------------------------------------
.calendar-list {
	.fleft {
		margin-right: 7px;
	}
	li {
		padding: 25px 0 20px;
	}
	.link-list {
		margin-top: 10px;
	}
	@include media-breakpoint-down(md) {
		.fleft {
			margin-right: 0;
		}
	}
}

//----------  list with links
//------------------------------------------------------------------------------
.link-list {
	@include base-float;
	a {
		display: block;
		margin-bottom: 14px;
	}
	.listed-link::before {
		left: 4px;
	}
}

//----------  inline list
//------------------------------------------------------------------------------
.inline-list {
	@include base-float;
	margin-bottom: 12px;
	li {
		float: left;
		display: inline-block;
		margin-right: 29px;
		color: $grey;
		&:last-of-type {
			margin-right: 0;
		}
	}
	a {
		font-size: 16px;
		font-weight: 300;
		color: $grey;
		text-decoration: underline;
	}
	.selected a {
		text-decoration: none;
	}
}

//----------  table list
//------------------------------------------------------------------------------
.table-list {
	@include base-float;
	text-align: left;
	.date {
		font-size: 14px;
		line-height: 15px;
		font-weight: bold;
		// letter-spacing: .1em;
		color: $grey-light;
		text-transform: uppercase;
		padding-top: 39px;
		span {
			color: $blue;
			font-size: 12px;
		}
	}
	h2 {
		color: $grey;
		// line-height: 29px;
		// letter-spacing: -.02em;
		margin-bottom: 0;
		a {
			color: inherit;
			font-size: inherit;
			line-height: inherit;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	tr {
		border-bottom: 1px solid $grey-bg;
		&:first-of-type {
			border-top: 1px solid $grey-bg;
		}
	}
	td {
		display: inline-block;
		padding-bottom: 32px;
		&:nth-child(2) {
			margin-right: 5%;
			padding-top: 29px;
		}
		&:last-of-type {
			padding-top: 33px;
		}
	}
	.link-list a {
		margin-bottom: 5px;
	}
	@include media-breakpoint-down(md) {
		.date {
			padding-top: 29px;
			// letter-spacing: .02em;
		}
		h2 {
			font-size: 18px;
			// line-height: 26px;
		}
		td {
			padding-bottom: 20px;
			&:nth-child(2) {
				padding-top: 20px;
			}
			&:last-of-type {
				padding-top: 25px;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.date {
			padding-top: 0;
			margin-bottom: 9px;
			// letter-spacing: 0;
		}
		h2 {
			font-size: 16px;
			// line-height: 24px;
			margin-bottom: 13px;
			// letter-spacing: -.03em;
		}
		tr {
			@include base-float;
			padding: 20px 0;
		}
		td {
			display: block;
			padding-bottom: 0;
			&:nth-child(2) {
				padding-top: 0;
			}
			&:last-of-type {
				padding-top: 0;
			}
		}
		.link-list {
			padding-left: 10px;
			a {
				display: inline-block;
				&:not(:last-of-type) {
					margin-right: 22px;
				}
			}
		}
		.bottom-link {
			margin-bottom: 45px;
		}
	}
}

.arrow-bottom-reverse {
	height: 42px;
	width: 100%;
	background: $white;
	.arrow-top {
		border-right: 1px solid $black;
		border-top: 1px solid $black;
		transform: rotate(45deg);
	}
}
