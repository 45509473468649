//----------  standard content page
//------------------------------------------------------------------------------
.standard-content {
	.white-bg-content {
		padding-top: 50px;
	}
	.text-with-image {
		margin-top: 8px;
	}
	.links-buttons {
		margin-bottom: 66px;
		.btn {
			margin-right: 62px;
		}
		.download-link {
			margin-right: 42px;
		}
	}
	.bullets-lists {
		margin-bottom: 46px;
	}
	@include media-breakpoint-down(md) {
		.wrapper {
			padding: 0;
		}
		.white-bg-content {
			padding-top: 30px;
		}
		.text-with-image {
			margin-top: 0;
		}
		.links-buttons {
			margin-bottom: 45px;
			p {
				margin-bottom: 38px;
			}
		}
		.bullets-lists {
			margin-bottom: 29px;
		}
	}
	@include media-breakpoint-down(sm) {
		.white-bg-content {
			padding: 30px 20px 0;
		}
		.links-buttons {
			p {
				margin-bottom: 46px;
			}
			.btn {
				margin-right: 0;
				margin-bottom: 48px;
			}
		}
		.bullets-lists {
			margin-bottom: 0;
		}
		.rte-content {
			ul, ol {
				margin-bottom: 38px;
			}
		}
	}
}

//----------  home page
//------------------------------------------------------------------------------
.home-page {
	top: 0;
	.highlight-boxes {
		margin: -177px auto 90px;
		.wrapper {
			box-shadow: 0 6px 30px -2px rgba(0, 0, 0, 0.58);
		}
	}
	.color-boxes {
		margin-bottom: 90px;
		.financial-box {
			background-color: $blue-dark;
		}
		.shareprice-box {
			background-color: $yellow;
		}
	}
	.pressreleases-wrapp {
		background-color: $white;
	}
	.shareholder-info {
		margin-bottom: 100px;
	}
	.share-price-box {
		.next-link {
			span {
				display: inline-block;
			}
			&:hover span {
				text-decoration: underline;
			}
		}
		.next-link,
		.next-link::before {
			color: $white;
		}
		.show-mobile {
			display: none;
		}
	}
	.reg-news {
		background-color: $white;
		padding: 64px 0 33px;
		text-align: center;
		overflow: auto;
		.section-title {
			font-size: 18px;
			// line-height: 20px;
			color: $blue;
			margin-bottom: 37px;
			text-transform: uppercase;
			font-weight: bold;
			display: none;
		}
	}
	.table-list {
		margin-bottom: 40px;
		.date {
			width: 17.5%;
			span {
				display: block;
			}
		}
		td {
			&:nth-child(2) {
				width: 71.3%;
			}
			&:last-of-type {
				width: 5.5%;
			}
		}
	}
	.background-img-box {
		@include base-float;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		position: relative;
		&::after {
			content: '';
			position: absolute;
			display: inline-block;
			top: 0;
			left: 50%;
			width: 0;
			height: 0;
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-top: 30px solid $white;
			margin-left: -10px;
			z-index: 2;
		}
		.wrapper {
			position: relative;
			min-height: 565px;
		}
		.text-overlay {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
		}
		span {
			display: block;
			color: $yellow;
			text-transform: uppercase;
			margin-bottom: 6px;
			font-weight: bold;
		}
		h1 {
			color: $white;
			max-width: 680px;
			// letter-spacing: -.01em;
		}
		h2 {
			color: $white;
			// line-height: 30px;
			padding-left: 27px;
			position: relative;
			max-width: 680px;
			margin-bottom: 45px;
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 4px;
				display: inline-block;
				background-color: $yellow;
				height: 100%;
				width: 3px;
			}
		}
		.btn {
			color: $white;
			padding: 0 25px;
			&:not(:last-of-type) {
				margin-right: 20px;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.background-img-box .text-overlay {
			padding: 0 70px;
		}
		.highlight-boxes {
			margin-top: 0;
		}
		.narrower .wrapper {
			padding: 0 65px;
		}
		.shareholder-info {
			margin-bottom: 0;
		}
		.reg-news {
			padding-top: 41px;
		}
		.table-list {
			.date {
				width: 21%;
			}
			td {
				&:nth-child(2) {
					width: 64%;
				}
				&:last-of-type {
					width: 10%;
				}
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.narrower .wrapper {
			padding: 0 20px;
		}
		.highlight-boxes {
			margin-bottom: 50px;
		}
		.share-price-box {
			&.narrower .wrapper {
				padding: 0 16px;
			}
			.income-box {
				h4 {
					margin-bottom: 17px;
				}
				.hide-mobile {
					display: none;
				}
				.total-income {
					margin-bottom: 5px;
				}
				.income-amount {
					margin-bottom: 0;
				}
				.fy-mobile {
					margin-bottom: -6px;
				}
			}
			.col-md-6 {
				margin-bottom: 23px;
			}
			.next-link {
				padding-right: 20px;
			}
			.show-mobile {
				display: block;
			}
		}
		.reg-news {
			padding: 31px 0 26px;
			&.narrower .wrapper {
				padding: 0 21px;
			}
			.section-title {
				font-size: 14px;
				margin-bottom: 22px;
			}
		}
		.table-list {
			margin-bottom: 25px;
			tr {
				padding-bottom: 16px;
				display: block;
			}
			.date {
				width: 100%;
				display: inline-block;
				span {
					display: inline-block;
					margin-left: 3px;
				}
			}
			td {
				display: inline-block;
				&:nth-child(2),
				&:last-of-type {
					width: 100%;
				}
			}
			h2 {
				margin-bottom: 8px;
			}
		}
		.background-img-box {
			&::after {
				display: none;
			}
			.text-overlay {
				padding: 0 20px;
			}
			h1 {
				font-size: 40px;
				max-width: 240px;
			}
			h2 {
				font-size: 16px;
				margin-bottom: 37px;
			}
			.btn {
				margin-right: 0;
				&:first-of-type {
					margin-bottom: 15px;
				}
				:not(:last-of-type) {
					margin-bottom: 17px;
				}
			}
		}
	}
}

//----------  news, download, search page
//------------------------------------------------------------------------------
.news-page,
.download-page {
	.tabs {
		margin-bottom: 0;
	}
	.inline-list {
		background-color: $grey-bg;
		margin-bottom: 0;
		li {
			padding: 15px;
			margin-right: 0;
			&:first-of-type {
				padding-left: 20px;
			}
			&.selected {
				background-color: $white;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.inline-list li {
			padding: 15px 10px;
			&:first-of-type {
				padding-left: 10px;
			}
		}
	}
}

.download-page,
.news-page,
.search-page {
	.white-bg-content {
		padding-top: 60px;
	}
	@include media-breakpoint-down(md) {
		.white-bg-content {
			padding-top: 40px;
		}
	}
}

//----------  news page
//------------------------------------------------------------------------------
.news-page {
	.tabs-container {
		margin-bottom: 54px;
	}
	.tabs a,
	.tab-heading {
		padding: 18px 20px 11px;
	}
	.tab-content {
		margin-top: 0;
	}
	.news-list {
		margin-bottom: 50px;
		.link-list {
			margin-top: 32px;
		}
	}
	@include media-breakpoint-down(md) {
		.tabs a,
		.tab-heading {
			padding: 18px 14px 11px;
		}
		.news-list {
			.date {
				margin-bottom: 14px;
			}
			h3 {
				font-size: 17px;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.white-bg-content {
			padding: 20px 16px 0;
		}
		.tabs a, .tab-heading {
			padding: 15px 14px 14px;
		}
		.tab-content {
			padding-bottom: 50px;
		}
		.news-list {
			.date {
				margin-bottom: 14px;
			}
			h3 {
				font-size: 16px;
			}
			.link-list {
				margin-top: 0;
				a {
					float: left;
					margin-right: 40px;
				}
				.fright {
					float: left;
				}
			}
		}
	}
}

//----------  download page
//------------------------------------------------------------------------------
.download-page {
	.news-list {
		margin-bottom: 65px;
		.link-list {
			margin-top: 13px;
			a {
				margin-bottom: 9px;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.white-bg-content {
			padding: 30px 16px 0;
		}
	}
}

//----------  calendar page
//------------------------------------------------------------------------------
.calendar-page {
	.white-bg-content {
		padding: 66px 0 32px;
		margin-bottom: 60px;
	}
	.tabs-container {
		margin-bottom: 30px;
		.tabs {
			margin-bottom: 0;
		}
	}
	@include media-breakpoint-down(md) {
		.white-bg-content {
			padding-bottom: 19px;
		}
		.calendar-list {
			li {
				padding-bottom: 5px;
			}
			.date {
				margin-bottom: 15px;
			}
			h2 {
				font-size: 18px;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.tabs-container {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}

//----------  search page
//------------------------------------------------------------------------------
.search-page {
	h2.intro {
		margin-bottom: 60px;
	}
	@include media-breakpoint-down(md) {
		h2.intro {
			margin-bottom: 20px;
		}
	}
	@include media-breakpoint-down(sm) {
		.white-bg-content {
			padding: 38px 16px 0;
		}
		h2.intro {
			margin-bottom: 27px;
		}
	}
}

//----------  search results
//------------------------------------------------------------------------------
.search-results {
	@include base-float;
	margin-bottom: 65px;
	li {
		@include base-float;
		padding: 24px 0 12px;
		border-bottom: 1px solid $grey;
	}
	h2 {
		color: $grey2;
		margin-bottom: 11px;
		a {
			color: inherit;
			font-size: inherit;
			line-height: inherit;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	@include media-breakpoint-down(md) {
		li {
			padding: 18px 0 0;
		}
		h2 {
			margin-bottom: 16px;
		}
		p {
			margin-bottom: 20px;
		}
	}
	@include media-breakpoint-down(sm) {
		h2 {
			font-size: 20px;
		}
	}
}

//----------  sitemap page
//------------------------------------------------------------------------------
.sitemap-page {
	.white-bg-content {
		padding-top: 81px;
	}
	.sitemap-field {
		@include base-float;
		margin-bottom: 20px;
	}
	h2 {
		text-transform: uppercase;
		margin-bottom: 0;
		a:hover {
			text-decoration: underline;
		}
	}
	a {
		color: $grey;
	}
	.link-list {
		margin: 34px 0 26px;
		li {
			@include base-float;
			display: block;
		}
		a {
			margin-bottom: 11px;
			font-size: 16px;
			line-height: 19px;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
			&::before,
			&:hover::before {
				text-decoration: none;
			}
		}
		ul li {
			padding-left: 28px;
		}
	}
	@include media-breakpoint-down(md) {
		.white-bg-content {
			padding-top: 58px;
		}
		.sitemap-field {
			margin-bottom: 30px;
			&:last-of-type {
				margin-bottom: 10px;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.white-bg-content {
			padding: 50px 16px 84px;
		}
	}
}

//----------  error page
//------------------------------------------------------------------------------
.error-page {
	.white-bg-content {
		padding: 52px 0 126px;
		margin-bottom: 60px;
	}
	h2 {
		margin-bottom: 32px;
	}
	p {
		margin-bottom: 35px;
	}
	.btn {
		padding: 0 23px;
	}
	@include media-breakpoint-down(md) {
		.white-bg-content {
			padding-bottom: 56px;
		}
	}
	@include media-breakpoint-down(sm) {
		.white-bg-content {
			padding: 58px 13px 56px;
			margin-bottom: 0;
		}
		h2 {
			margin-bottom: 28px;
		}
	}
}

//----------  people page
//------------------------------------------------------------------------------
.people-page {
	&.no-committees {
		top: 0;
		margin: 50px auto 30px;
		.white-bg-content {
			display: none;
		}
	}
	.people-wrap {
		width: 100%;
		position: relative;
		float: left;
	}
	.white-bg-content {
		padding: 29px;
		margin-bottom: 23px;
		min-height: 135px;
		.committee-items span {
			margin-right: 45px;
			max-width: 145px;
			font-size: 12px;
		}
	}
	h5:not(.position) {
		font-weight: bold;
		margin-bottom: 20px;
	}
	.committee-items {
		@include base-float;
		&.legend {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			span {
				width: 100%;
				max-width: 160px;
				padding-left: 20px;
				margin-right: 25px;
				margin-bottom: 25px;
				&::before {
					bottom: auto;
					@include align(absolute, y);
				}
			}
			.big-dot {
				padding-left: 25px;
			}
			&.wide span {
				max-width: 100%;
			}
		}
		span {
			float: left;
			margin-right: 8px;
			font-size: 14px;
			color: $grey;
			position: relative;
			min-height: 17px;
			width: 17px;
			&::before {
				content: '';
				display: inline-block;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}
		.blue::before {
			background-color: $blue;
		}
		.yellow::before {
			background-color: $yellow;
		}
		.red::before {
			background-color: $red;
		}
		.green::before {
			background-color: $green;
		}
		.grey::before {
			background-color: $grey;
		}
		.big-dot {
			width: 25px;
			&::before {
				width: 18px;
				height: 18px;
				top: 0;
				left: 0;
			}
			&::after {
				content: 'C';
				display: inline-block;
				position: absolute;
				top: 1px;
				left: 0;
				color: $white;
				z-index: 2;
				font-size: 12px;
				width: 18px;
				text-align: center;
			}
		}
	}
	.member-item {
		position: relative;
		margin-bottom: 23px;
		overflow: hidden;
		&:hover {
			h2, .position {
				text-decoration: underline;
			}
		}
	}
	.member-info {
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 20px 30px 37px;
		h2 {
			color: $white;
			margin-bottom: 10px;
		}
		.position {
			color: $white;
		}
		.committee-items {
			margin-bottom: 10px;
		}
		&:hover {
			cursor: pointer;
		}
	}
	.position {
		font-size: 12px;
		text-transform: uppercase;
		display: block;
	}
	.img-holder {
		position: relative;
		img {
			width: 100%;
		}
		.dark-overlay {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: linear-gradient(to top, rgba($black, .6) 10%, rgba($black, 0) 100%);
		}
	}
	@include media-breakpoint-down(md) {
		.white-bg-content {
			padding-bottom: 20px;
		}
		.committee-items.legend {
			flex-wrap: wrap;
		}
	}
	@include media-breakpoint-down(sm) {
		.white-bg-content {
			margin-bottom: 0;
		}
		.committee-items.legend {
			display: block;
			padding-left: 11px;
			span {
				max-width: 100%;
				margin-bottom: 14px;
				line-height: 12px;
				&::before {
					left: -4px;
					margin-top: -3px;
				}
			}
			.big-dot {
				padding-left: 20px;
				&::before {
					margin-top: -3px;
					left: -8px;
				}
				&::after {
					top: 0;
					left: -8px;
				}
			}
		}
	}
}

.member-details {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	display: none;
	.member-details-wrapper {
		display: block;
		background-color: $white;
		padding: 46px 0;
		position: relative;
		h2 {
			margin-bottom: 10px;
		}
		.position {
			color: $blue;
			margin-bottom: 20px;
		}
		.close-btn {
			top: 35px;
			right: 27px;
			width: 30px;
			height: 30px;
			z-index: 5;
			&::after,
			&::before {
				width: 24px;
			}
		}
	}
	.wrapper {
		padding: 0;
	}
	.committee-position {
		font-size: 16px;
		color: $grey;
		display: block;
		margin-bottom: 15px;
		padding-top: 25px;
		border-top: 1px solid $grey;
	}
	.committee-items {
		&.legend {
			span {
				margin-bottom: 7px;
			}
		}
	}
	.person-info {
		margin-bottom: 15px;
	}
	@include media-breakpoint-down(sm) {
		.member-details-wrapper {
			padding: 20px;
		}
	}
}

//----------  investors page
//------------------------------------------------------------------------------
.investors-page {
	top: 0;
	.white-bg-content {
		padding: 0;
		margin: 60px 0;
	}
	section {
		@include base-float;
		span {
			display: block;
		}
	}
	.financial-highlights {
		background: linear-gradient(to bottom, $blue 0%, $blue-dark 100%);
		padding: 85px 0 39px;
		text-align: center;
		position: relative;
		&::after {
			content: '';
			position: absolute;
			display: inline-block;
			top: 0;
			left: 50%;
			width: 0;
			height: 0;
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-top: 30px solid $grey-bg;
			margin-left: -10px;
		}
		.section-title {
			color: $white;
			margin-bottom: 64px;
		}
		.btn {
			color: $white;
		}
	}
	.section-title {
		font-size: 20px;
		font-weight: bold;
		text-transform: uppercase;
		text-align: center;
	}
	.income-box-holder {
		@include base-float;
		margin-bottom: 50px;
		border-top: 1px solid $white;
		border-bottom: 1px solid $white;
		.border-left, .border-right {
			position: relative;
			&::before, &::after {
				content: '';
				position: absolute;
				width: 1px;
				background-color: $white;
				display: inline-block;
			}
			&::before {
				top: 0;
			}
			&::after {
				bottom: 0;
			}
		}
		.border-left::before,
		.border-right::after {
			height: 66%;
		}
		.border-left::after,
		.border-right::before {
			height: 19%;
		}
		.border-left {
			&::before, &::after {
				left: 0;
			}
		}
		.border-right {
			padding: 37px 0 45px;
			&::before, &::after {
				right: 0;
			}
		}
		.arrow-left, .arrow-right {
			border-top: 1px solid $white;
			border-right: 1px solid $white;
		}
		.arrow-left {
			bottom: 21%;
			left: -13px;
			transform: rotate(-135deg);
		}
		.arrow-right {
			top: 21%;
			right: -13px;
			transform: rotate(45deg);
		}
	}
	.shareholder-info {
		padding: 65px 0 95px;
		.section-title {
			color: $grey;
			margin-bottom: 50px;
		}
	}
	.reg-news {
		background-color: $white;
		padding: 53px 0 33px;
		text-align: center;
		.section-title {
			color: $blue;
			margin-bottom: 49px;
		}
	}
	.table-list {
		margin-bottom: 20px;
		.date {
			width: 15.5%;
		}
		td {
			&:nth-child(2) {
				width: 70%;
			}
			&:last-of-type {
				width: 8.8%;
			}
		}
	}
	@media (max-width: 1200px) and (min-width: 992px) {
		.financial-highlights {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	@media (max-width: 1045px) and (min-width: 1010px) {
		.income-box-holder {
			.border-left::before,
			.border-right::after {
				height: 67%;
			}
			.border-left::after,
			.border-right::before {
				height: 19.3%;
			}
		}
	}
	@media (max-width: 1009px) and (min-width: 992px) {
		.income-box-holder {
			.border-left::before,
			.border-right::after {
				height: 67.7%;
			}
			.border-left::after,
			.border-right::before {
				height: 19.5%;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.white-bg-content {
			margin: 0;
		}
		.narrower .wrapper {
			padding: 0 65px;
		}
		.section-title {
			font-size: 16px;
		}
		.financial-highlights {
			padding-top: 68px;
			&::after {
				display: none;
			}
			.section-title {
				margin-bottom: 36px;
			}
		}
		.income-box-holder {
			margin-bottom: 27px;
			.col-md-6 {
				margin-bottom: 28px;
			}
			.border-left::before,
			.border-right::after {
				height: 70%;
			}
			.border-left::after,
			.border-right::before {
					height: 19.5%;
			}
			.border-right {
				padding: 25px 0 6px;
			}
		}
		.shareholder-info {
			padding: 48px 0 0;
			.section-title {
				margin-bottom: 43px;
			}
		}
		.reg-news {
			padding: 43px 0 33px;
			.section-title {
				margin-bottom: 38px;
			}
		}
		.table-list {
			margin-bottom: 40px;
			.date {
				width: 21%;
			}
			td:nth-child(2) {
				width: 64%;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		section {
			span {
				display: inline-block;
			}
		}
		.financial-highlights.narrower .wrapper {
			padding: 0 25px;
		}
		.shareholder-info {
			padding-top: 43px;
			&.narrower .wrapper {
				padding: 0 16px;
			}
			.section-title {
				margin-bottom: 66px;
			}
		}
		.income-box-holder {
			.border-left::before,
			.border-right::after {
				height: 74.2%;
			}
			.border-left::after,
			.border-right::before {
				height: 20.2%;
			}
		}
		.section-title {
			font-size: 14px;
		}
		.reg-news {
			&.narrower .wrapper {
				padding: 0 20px;
			}
			.section-title {
				margin-bottom: 34px;
			}
		}
		.table-list {
			.date {
				width: 100%;
			}
			td {
				&:nth-child(2),
				&:last-of-type {
					width: 100%;
				}
			}
		}
	}
}
