@media print {
	body {
		background-color: $white;
		color: $black;
		margin: 0;
		padding: 0;
		text-align: left;
	}
	a:link, a:visited {
		background-color: $white;
		color: $black;
		text-decoration: underline;
	}
	a:not([href="javascript:;"])::after {
		content: " [" attr(href) "] ";
	}
	h1, h2, h3, h4, h5, h6, p, span, a {
		background-color: none;
		color: $black;
	}
	// add elements that are going to be hidden
	.header, .footer, .temp, .cookies, .search-toggle,
	.highlight-boxes, .shareholder-info, .btn {
		display: none;
	}
	.member-details {
		display: block;
	}
	.banner {
		height: 100px;
		background: none;
		.wrapper {
			height: 100px;
		}
		.banner-text {
			padding-top: 0;
		}
	}
	.content {
		padding-top: 0;
	}
}
