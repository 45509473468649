//----------  variables
//------------------------------------------------------------------------------

$font-default: 'TBC Din Nusxuri', sans-serif;

//rgb var
$rgba-header: rgba(144, 163, 175, 0.1);

// Colors
$white: #fff;
$black: #000;
$grey: #484848;
$grey-light: #808080;
$grey-bg: #ededed;
$gainsboro: #d8d8d8;
$grey2: #2e2d2d;
$red: #da1e41;
// $orange: #ffa000;
// $yellow: #fbba00;
$orange: #fdd10f;
$yellow: #fdd10f;
$green: #a1c03a;
$blue: #00b0f0;
$blue-dark: #005195;
$blue-lochmara: #0089cc;
$blue-cerulean: #039bd3;
$banner-overlay: #17252d;


//----------  Bootstrap grid
//------------------------------------------------------------------------------

$enable-flex:               true !default; //Set this to false if you don't want flexbox
$enable-grid-classes:       true !default;

//----------  Media queries breakpoints - Grid breakpoint
//------------------------------------------------------------------------------

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

//----------  Grid containers
//------------------------------------------------------------------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;

//----------  Grid columns
//------------------------------------------------------------------------------

$grid-columns:               12 !default;
$grid-gutter-width-base:     30px !default;
$grid-gutter-widths: (
  xs: $grid-gutter-width-base,
  sm: $grid-gutter-width-base,
  md: $grid-gutter-width-base,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base
) !default;

$headings-margin-bottom: (1rem / 2) !default;
$headings-font-family:   inherit !default;
$headings-font-weight:   500 !default;
$headings-line-height:   1.1 !default;

$font-size-h1: 2.5rem !default;
$font-size-h2: 2rem !default;
$font-size-h3: 1.75rem !default;
$font-size-h4: 1.5rem !default;
$font-size-h5: 1.25rem !default;
$font-size-h6: 1rem !default;
