//----------  footer
//------------------------------------------------------------------------------
.footer {
	@include base-float;
	a {
		color: $white;
		&::before {
			color: $white;
		}
		&:hover::before {
			color: $white;
		}
	}
	.top {
		@include base-float;
		background-color: $blue;
		padding: 30px 0 22px;
		position: relative;
		overflow: hidden;
	}
	.bg {
		width: 44%;
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		background-color: $blue-lochmara;
		font-size: 0;
		transform: skewX(-30deg);
		&::before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 50%;
			height: 100%;
			background-color: $blue-lochmara;
			transform: skewX(30deg);
		}
	}
	.show-on-mobile {
		display: none;
		background-color: $blue-dark;
		color: $white;
		font-weight: 400;
		padding: 18px 15px;
		text-transform: uppercase;
		&::after {
			@include icon-font;
			content: $fa-var-chevron-down;
			font-size: 15px;
			float: right;
			margin-right: 10px;
			margin-top: 3px;
		}
	}
	.footer-logo {
		margin-bottom: 26px;
	}
	.link-list a {
		@include base-float;
		font-size: 14px;
		line-height: 17px;
		margin-bottom: 5px;
		&:hover {
			text-decoration: underline;
		}
	}
	.external-link {
		margin-bottom: 5px;
		&::before {
			font-size: 14px;
			margin-right: 5px;
		}
		&:hover span {
			text-decoration: underline;
		}
	}
	h4 {
		text-transform: uppercase;
		color: $white;
		position: relative;
		margin: 4px 0 30px;
		&::after {
			content: '';
			display: inline-block;
			width: 41px;
			height: 1px;
			background-color: $white;
			position: absolute;
			bottom: -10px;
			left: 0;
		}
	}
	.bottom {
		@include base-float;
		padding: 22px 0;
		background-color: $grey;
		color: $white;
		span {
			font-size: 14px;
			line-height: 20px;
		}
		a {
			float: right;
			color: $white;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.wrapper {
			padding: 0 30px;
		}
		.bg {
			width: 46%;
		}
	}
	@include media-breakpoint-down(sm) {
		.wrapper {
			padding: 0 20px;
		}
		.show-on-mobile {
			display: block;
		}
		.hidden-on-mobile {
			display: none;
		}
		.top {
			display: none;
		}
		.link-list {
			margin-right: 0;
			margin-left: 0;
			// a {
			// 	letter-spacing: -.1em;
			// }
		}
		.col-lg-4 {
			text-align: center;
		}
		.bottom {
			text-align: center;
			span, a {
				display: block;
			}
			span {
				margin-bottom: 15px;
			}
			a {
				float: none;
			}
		}
	}
}
