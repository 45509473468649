//----------  selectric
//------------------------------------------------------------------------------
.selectric-wrapper {
  position: relative;
  cursor: pointer;
  max-width: 950%;
  width: 100%;
  display: inline-block;
  margin: 0;
}
.selectric-responsive {
  width: 100%;
}
.selectric {
	height: 40px;
	top: 0;
	left: 0;
	border: 0;
	background-color: $white;
	position: relative;
	border-radius: 4px;
	padding-right: 40px;
	padding-left: 0;
	width: 481px;
}
.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 38px 0 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  padding: 12px 35px 12px 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.selectric .button {
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -16px;
  width: 38px;
  height: 32px;
  text-align: center;
  &::after {
	  content: '\f2f9';
	  @include icon-font;
	  position: absolute;
	  top: 10px;
	  right: 0;
	  margin: auto;
	  width: 38px;
	  height: 32px;
  }
}
.selectric-hover .selectric {
  border-color: $grey;
}
.selectric-hover .selectric .button::after {
  border-top-color: $black;
}
.selectric-open {
  z-index: 12;
  height: 100%;
}
.selectric-open .selectric {
  border-color: $black;
}
.selectric-open .selectric-items {
  display: block;
}
.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
	 -moz-user-select: none;
	  -ms-user-select: none;
		  user-select: none;
}
.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}
.selectric-hide-select select {
  position: absolute;
  left: -100%;
  display: none;
}
.selectric-input {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  margin: 0;
  padding: 0;
  width: 1px;
  height: 0;
  outline: none;
  border: 0;
  background: none;
  opacity: 0;
}
.selectric-temp-show {
  position: absolute;
  visibility: hidden;
  display: block;
}
// Items box
.selectric-items {
  display: none;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  background: $white;
  z-index: -1;
}
.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}
.selectric-scroll li {
	border-top: 1px solid $grey;
}
.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}
.selectric-items ul, .selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;

}
.selectric-items li {
  display: block;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-bottom: 0;
  border-right: 0;
}
.selectric-items li.selected {
  background: $white;
  color: $black;
}
.selectric-items li:hover {
  background: $grey;
  color: $white;
}
.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  background: none;
  color: #666;
  -webkit-user-select: none;
	 -moz-user-select: none;
	  -ms-user-select: none;
		  user-select: none;
}
.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  -webkit-user-select: none;
	 -moz-user-select: none;
	  -ms-user-select: none;
		  user-select: none;
  background: none;
  color: #444;
}
.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}
.selectric-items .selectric-group li {
  padding-left: 25px;
}
