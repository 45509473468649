//----------  helpers
//------------------------------------------------------------------------------
%clearfix {
	&::before, &::after {
		content: '';
		display: table;
	}

	&::after {
		clear: both;
	}
}

* {
	box-sizing: border-box;

	&::before,
	&::after {
		box-sizing: border-box;
	}
}

.clearfix {
	@extend %clearfix;
}

.hide {
	display: none;
}

.clear {
	clear: both;
}

.fleft {
	float: left;
}

.fright {
	float: right;
}

.hidden {
	opacity: 0;
}

.visible {
	opacity: 1;
}

.slideinup {
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1000;
	// backface-visibility: hidden;
	// perspective: 1000;
	animation-duration: 1.5s;
	animation-timing-function: ease;
	animation-name: slideinup;
}

@keyframes slideinup {
	0% {
		opacity: 0;
		transform: translate3d(0, 200px, 0);
		visibility: visible;
	}
	40% {
		opacity: 0;
		transform: translate3d(0, 200px, 0);
		visibility: visible;
	}
	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}
}

.slideinleft,
.slideinright {
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1000;
	// backface-visibility: hidden;
	// perspective: 1000;
	animation-fill-mode: both;
	&.fast {
		animation-duration: .5s;
	}
	&.slow {
		animation-duration: 1s;
	}
}

.slideinleft {
	animation-name: slideinleft;
}

.slideinright {
	animation-name: slideinright;
}

@keyframes slideinleft {
	0% {
		transform: translateX(-100%);
		visibility: visible;
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes slideinright {
	0% {
		transform: translateX(100%);
		visibility: visible;
	}
	100% {
		transform: translateX(0);
	}
}
