//----------  header
//------------------------------------------------------------------------------
.header {
	@include base-float;
	position: fixed;
	top: 0;
	left: 0;
	transition: transform 0.3s ease-out;
	z-index: 1000;
	&.hidden {
		transform: translateY(-200px);
	}
	.logo {
		padding-left: 29px;
	}
	.top {
		@include base-float;
		color: $white;
		background-color: $blue;
		min-height: 55px;
		line-height: 55px;
		padding-left: 29px;
		.fleft {
			font-weight: bold;
		}
	}
	.dropdown-wrapp {
		float: right;
		font-size: 14px;
		font-weight: 400;
		background-color: $blue-dark;
		cursor: pointer;
		position: relative;
		.dropdown-btn {
			min-width: 189px;
			padding: 0 60px 0 28px;
			font-weight: bold;
			&::after {
				@include icon-font;
				content: $fa-var-chevron-down;
				margin-left: 20px;
				position: absolute;
				right: 20px;
				top: 50%;
				margin-top: -8px;
			}
			&.active::after {
				content: $fa-var-close;
			}
			i::before {
				font-size: 19px;
				margin-right: 3px;
			}
		}
		.dropdown-list {
			display: none;
			min-width: 240px;
			background-color: $blue;
			padding: 25px 30px;
			position: absolute;
			top: 100%;
			right: 0;
			z-index: 300;
		}
		.external-link {
			font-size: 14px;
			line-height: 17px;
			color: $white;
			margin-bottom: 15px;
			&:last-of-type {
				margin-bottom: 0;
			}
			&::before {
				color: $white;
				text-decoration: underline;
			}
			&::before,
			&:hover::before {
				text-decoration: none;
			}
			&:hover {
				color: $white;
				text-decoration: underline;
			}
		}
	}
	.email-link {
		float: right;
		color: $white;
		font-size: 14px;
		font-weight: bold;
		padding: 0 32px 0 20px;
		&::before {
			@include icon-font;
			content: $fa-var-envelope;
			color: $blue-dark;
			margin-right: 5px;
		}
		&:hover span {
			text-decoration: underline;
		}
	}
	.share-price {
		float: right;
		background-color: $blue-cerulean;
		padding: 14px 33px;
		font-size: 14px;
		line-height: normal;
		font-weight: bold;
		color: $white;
		.value {
			font-size: 22px;
			font-weight: bold;
		}
		.arrow {
			margin-left: 10px;
			&.up::after {
				@include icon-font;
				content: $fa-var-caret-up;
			}
			&.down::after {
				@include icon-font;
				content: $fa-var-caret-down;
			}
		}

	}
	.bottom {
		@include base-float;
		min-height: 80px;
		line-height: 80px;
		background-color: $white;
		position: relative;
		box-shadow: 0 2px 5px 0 rgba($black, .15);
	}
	.search-toggle {
		position: relative;
		top: 0;
		&::after {
			@include icon-font;
			content: $fa-var-search;
			font-size: 22px;
			color: $grey-light;
		}
		.close-search {
			@include align(absolute, xy);
			opacity: 0;
			&::before, &::after {
				content: '';
				position: absolute;
				top: -2px;
				left: 0;
				width: 20px;
				height: 3px;
				background-color: $grey-light;
				transition: background-color .4s ease-out;
			}
			&::before {
				top: -2px;
			}
			&::after {
				bottom: -1px;
			}
		}
		&.active {
			text-align: left;
			&::after {
				opacity: 0;
			}
			.close-search {
				opacity: 1;
			}
		}
		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 24px;
			height: 30px;
			width: 1px;
			background-color: $gainsboro;
		}
	}
	.search-form {
		display: none;
		position: absolute;
		top: 0;
		left: 200px;
		right: 87px;
		z-index: 10;
		input {
			width: 100%;
			height: 80px;
			font-size: 23px;
			line-height: 22px;
			border: 0;
			padding: 20px;
			color: $grey-light;
			font-weight: 700;
		}
	}
	.open-menu {
		display: none;
		float: right;
		text-indent: -9999px;
		width: 87px;
		text-align: center;
		position: relative;
		cursor: pointer;
		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 24px;
			height: 30px;
			width: 1px;
			background-color: $gainsboro;
		}
		.hamburger {
			width: 25px;
			height: 2px;
			background-color: $grey-light;
			display: inline-block;
			transition: background-color .4s ease-out;
			@include align(absolute, xy);
			&::before, &::after {
				content: '';
				position: absolute;
				width: 100%;
				left: 0;
				height: 2px;
				display: block;
				background-color: $grey-light;
			}
			&::before {
				top: -8px;
				transition: top .4s ease-out, transform .4s ease-out;
			}
			&::after {
				bottom: -8px;
				transition: bottom .4s ease-out, transform .4s ease-out;
			}
		}
		&.active .hamburger {
			background-color: transparent;
			&::before {
				top: 0;
				transform: rotate(45deg);
			}
			&::after {
				bottom: 0;
				transform: rotate(-45deg);
			}
		}
	}
	.main-nav {
		float: right;
		li {
			float: left;
			margin: 0 15px;
			a {
				&::before {
					display: none;
					content: '';
					position: absolute;
					height: 3px;
					bottom: 0;
					right: 0;
					left: 0;
					background-color: $blue;
				}
				&:hover::before,
				&.active::before {
					display: block;
				}
			}
		}
		a {
			font-size: 16px;
			color: $grey;
			font-weight: 700;
			text-transform: uppercase;
			position: relative;
			height: 100%;
			float: left;
		}
		.dropdown-btn {
			display: none;
		}
		.play-btn {
			position: absolute;
			height: 62px;
			font-size: 0;
			top: 50%;
			margin-top: -31px;
		}
		.link-list a:hover::before {
			display: none;
		}
		&.open {
			position: fixed;
			bottom: 0;
			top: 0;
			left: 0;
			right: 0;
			.nav-dropdown,
			.third-level-nav {
				position: fixed;
				bottom: 0;
				top: 0;
				left: 100%;
				right: 0;
				overflow: auto;
				height: auto;
				padding-top: 30px;
			}
		}
	}
	.toggler {
		display: none;
		width: 40px;
		height: 40px;
		line-height: initial;
		text-align: center;
		cursor: pointer;
		&::before {
			@include icon-font;
			content: $fa-var-plus;
			color: $blue;
			line-height: 40px;
		}
	}
	.nav-dropdown {
		display: none;
		padding: 52px 0;
		position: absolute;
		top: 100%;
		right: 0;
		left: 0;
		z-index: 10;
		background-color: $white;
		overflow: hidden;
		&::before {
			content: '';
			display: block;
			position: absolute;
			right: 0;
			left: 0;
			top: 0;
			height: 6px;
			box-shadow: inset 0 3px 3px 0 rgba($black, .2);
			// background-image: linear-gradient(to top, transparent, #d3d3d3);
		}
		&::after {
			content: '';
			display: block;
			background-color: $grey-bg;
			width: 40%;
			transform: rotate(45deg);
		}
	}
	.back, .parent-name, .parent {
		display: none;
	}
	.intro-text {
		padding-right: 50px;
		h2 {
			font-size: 30px;
			font-weight: 700;
			color: $blue;
			text-transform: uppercase;
		}
		p {
			position: relative;
			padding-left: 30px;
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				background-color: $yellow;
				width: 1px;
			}
		}
	}
	.link-list {
		max-width: 270px;
		li {
			width: 100%;
			padding: 5px 35px 10px 0;
			border-bottom: 1px solid $grey;
			line-height: normal;
			margin: 0;
			position: relative;
			&.active {
				a {
					color: $blue-dark;
					padding-left: 20px;
				}
				.arrow::before {
					color: $blue-dark;
				}
			}
		}
		a {
			width: 100%;
			color: $grey;
			text-transform: none;
			font-weight: 400;
			margin-bottom: 0;
			&:hover {
				text-decoration: none;
				color: $blue;
			}
		}
		.arrow {
			width: 30px;
			height: 30px;
			right: 0;
			top: 50%;
			@include align(absolute, y);
			text-align: right;
			&::before {
				@include icon-font;
				content: $fa-var-chevron-circle-right;
				line-height: 30px;
				color: $blue;
				font-size: 14px;
			}
			&:hover {
				cursor: pointer;
			}
		}
	}
	.static-content {
		display: block;
		&.hide {
			display: none;
		}
	}
	.third-level-nav {
		display: none;
		&.show {
			display: block;
		}
	}
	.img-video-holder {
		h4 {
			font-size: 14px;
			// line-height: 17px;
			color: $blue;
			text-transform: uppercase;
			font-weight: 700;
			margin-bottom: 4px;
		}
		p {
			font-size: 18px;
			// line-height: 21px;
		}
	}
	.img-holder {
		margin-bottom: 15px;
		position: relative;
		img {
			width: 100%;
		}
	}
	.bg {
		width: 49%;
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		background-color: $grey-bg;
		font-size: 0;
		transform: skewX(27deg);
		z-index: -1;
		&::before {
			content: '';
			display: block;
			position: absolute;
			right: 0;
			top: 0;
			width: 50%;
			height: 100%;
			background-color: $grey-bg;
			transform: skewX(-27deg);
		}
	}
	.show-on-mobile {
		display: none;
	}
	.cookies {
		display: block;
		padding: 15px;
		// background-color: $blue-dark;
		background-color: $grey;
		.cookies-wrap {
			position: relative;
			p {
				color: $white;
				font-size: 14px;
				font-family: $font-default;
				margin-bottom: 0;
				width: 80%;
				a {
					color: $white;
					text-decoration: underline;
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		transition: transform 0.7s ease-out;
		box-shadow: 0 2px 5px rgba($black, 0.15);
		.bottom::after {
			content: '';
			position: absolute;
			bottom: -6px;
			left: 0;
			right: 0;
			width: 100%;
			height: 6px;
			z-index: 200;
			box-shadow: inset 0 2px 5px rgba($black, 0.15);
		}
		.bg {
			display: none;
		}
		.email-link {
			display: none;
		}
		.search-form {
			right: 167px;
			input {
				font-size: 18px;
				height: 75px;
			}
		}
		.main-nav {
			display: flex;
			-webkit-backface-visibility: hidden;
			flex-direction: column;
			position: fixed;
			top: 0;
			right: 0;
			left: 100%;
			z-index: 200;
			box-shadow: inset 0 2px 5px rgba($black, 0.15);
			background-color: $blue-dark;
			ul {
				background: $white;
			}
			li {
				@include base-float;
				margin: 0;
				border-bottom: 1px solid $gainsboro;
				position: relative;
				ul {
					box-shadow: none;
				}
				&.has-children {
					padding-right: 50px;
					.toggler {
						display: block;
						position: absolute;
						right: 6px;
						top: 4px;
					}
				}
				a {
					&::before {
						height: 0;
					}
				}
			}
			a {
				width: 100%;
				font-size: 14px;
				line-height: 17px;
				font-weight: 400;
				padding: 15px 20px;
				&:hover {
					color: $blue;
				}
			}
		}
		.open-menu {
			display: inline-block;
		}
		.search-toggle::before {
			display: none;
		}
		.nav-dropdown {
			position: fixed;
			-webkit-backface-visibility: hidden;
			left: auto;
			right: -100%;
			display: block;
			top: 4px;
			width: 100%;
			padding: 25px 0;
			height: 100vh;
			&.open {
				padding: 0;
				position: fixed;
				top: 90px;
				bottom: 0;
			}
			.wrapper {
				margin: 0;
				padding: 0 40px;
			}
			&::before {
				display: none;
			}
			.link-list {
				float: left;
				clear: both;
				width: 100%;
				max-width: none;
			}
			.back {
				display: block;
				height: auto;
				font-size: 14px;
				color: $grey-light;
				text-transform: uppercase;
				padding-left: 0;
				padding-right: 0;
				&::before {
					content: $fa-var-chevron-circle-left;
					@include icon-font;
					margin-left: -22px;
					margin-right: 10px;
					color: $blue;
					background-color: transparent;
					bottom: auto;
				}
			}
		}
		.parent-name {
			font-size: 14px;
			line-height: normal;
			text-transform: uppercase;
			float: left;
			display: block;
		}
		.intro-text,
		.img-video-holder {
			display: none;
		}
		.link-list {
			li {
				padding: 0;
			}
			a {
				padding: 15px 0;
			}
		}
		.third-level-nav {
			position: absolute;
			right: -100%;
			display: block;
			background-color: $white;
			height: 100%;
			padding: 0 50px;
			.link-list li {
				border: 0;
			}
			.parent {
				display: block;
				float: left;
				line-height: normal;
				margin-top: 15px;
				clear: both;
				width: 100%;
				border-bottom: 1px solid $black;
				padding-bottom: 15px;
				&::before {
					content: $fa-var-chevron-circle-left;
					@include icon-font;
					margin-right: 10px;
					color: $blue;
					font-size: 14px;
				}
			}
		}
	}
	@include media-breakpoint-down(sm) {
		min-height: 70px;
		line-height: 70px;
		.top {
			padding-left: 19px;
			min-height: 30px;
			span {
				font-size: 12px;
				line-height: 30px;
			}
		}
		.bottom {
			min-height: 70px;
			line-height: 70px;
		}
		.logo {
			padding-left: 21px;
			img {
				width: 146px;
			}
		}
		.open-menu {
			width: 65px;
		}
		.dropdown-wrapp,
		.share-price {
			display: none;
		}
		.search-form {
			left: 0;
			right: 102px;
			input {
				height: 65px;
				padding: 20px 15px;
				font-size: 16px;
			}
		}
		.search-toggle {
			width: 54px;
		}
		.main-nav  {
			 overflow-y: scroll;
			// overflow-x: hidden;
			// min-height: 100vh;
			.dropdown-btn {
				display: block;
			}
		}
		.nav-dropdown {
			padding-top: 0;
			top: 3px;
		}
		.dropdown-wrapp {
			width: 100%;
			color: $white;
			font-weight: 400;
			.dropdown-btn {
				line-height: 17px;
				padding-left: 0;
				padding: 20px;
				position: relative;
			}
			&::after {
				float: right;
			}
			.dropdown-list {
				width: 100%;
				padding: 20px;
				position: relative;
				float: left;
			}
			.external-link {
				padding: 0;
			}
		}
		.hidden-on-mobile {
			display: none;
		}
		.show-on-mobile {
			display: block;
		}
	}
}
