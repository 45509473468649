//----------  button
//------------------------------------------------------------------------------
.btn {
	border: 2px solid $yellow;
	color: $grey;
	height: 50px;
	line-height: 46px;
	font-weight: bold;
	padding: 0 14px;
	display: inline-block;
	font-size: 14px;
	text-transform: uppercase;
	transition: 0.5s ease;
	text-decoration: none;
	&::before {
		content: $fa-var-chevron-circle-right;
		@include icon-font;
		margin-right: 5px;
		position: relative;
		text-decoration: none;
	}
	&:hover {
		background-color: $yellow;
		color: $grey;
	}
	&.download-link {
		&::before {
			content: $fa-var-download;
			position: relative;
			top: 0;
			left: 0;
		}
	}
}
.play-btn {
	width: 62px;
	height: 62px;
	background-color: $yellow;
	border-radius: 50%;
	position: absolute;
	font-size: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 0 37px 29px;
	box-shadow: 0 6px 30px -2px rgba(0, 0, 0, 0.58);
	&::after {
		content: '\f04b';
		@include icon-font;
		display: block;
		font-size: 30px;
		color: $white;
		position: absolute;
		@include align(absolute, xy);
		margin-left: 4px;
	}
	i {
		width: 29px;
		height: 11px;
		top: 37px;
		display: block;
		position: absolute;
		transform: rotate(-30deg);
		box-shadow: inset 1px 6px 24px -5px #cd9800;
		left: 22px;
	}
	&:hover {
		cursor: pointer;
	}
}
.close-wrapp {
	float: right;
	width: 87px;
	text-align: center;
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 20px;
	.close-btn {
		right: 2px;
		opacity: 1;
		top: -7px;
		transition: opacity .4s ease-out;
		&::before, &::after {
			background-color: $white;
		}
	}
}

.close-btn {
	position: absolute;
	font-size: 0;
	width: 20px;
	height: 0;
	&::before, &::after {
		content: '';
		position: absolute;
		top: -2px;
		left: 0;
		width: 20px;
		height: 3px;
		background-color: $grey-light;
		transition: background-color .4s ease-out;
	}
	&::before {
		top: -2px;
		transform: rotate(-45deg);
	}
	&::after {
		bottom: -1px;
		transform: rotate(45deg);
	}
}
