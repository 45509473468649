//----------  banner
//------------------------------------------------------------------------------
.banner {
	@include base-float;
	min-height: 450px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	.wrapper {
		position: relative;
		height: 363px;
	}
	&.higher {
		min-height: 716px;
		.wrapper {
			height: 540px;
		}
		.banner-text {
			width: 100%;
		}
	}
	///////////////////////////
	&.has-video {
		position: relative;
		overflow: hidden;
		height: 716px;
		.wrapper {
			position: static;
		}
		.banner-text {
			padding-top: 0;
			margin-top: -50px;
		}
		.video-holder {
			position: relative;
			height: 0;
			overflow: hidden;
			padding-bottom: 56.25%;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			.player .controls-wrapper {
				display: none;
			}
		}
	}
	video {
		min-width: 100%;
		min-height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.video-fallback {
		display: none;
	}
	///////////////////////////
	&.investors-banner .wrapper {
		height: 450px;
	}
	.banner-text {
		position: absolute;
		@include align(absolute, xy);
		color: $white;
		text-align: center;
		max-width: 660px;
		width: 100%;
		padding-top: 52px;
		span {
			font-weight: bold;
			font-size: 16px;
			text-transform: uppercase;
			color: $yellow;
			display: block;
			margin-bottom: 3px;
		}
		h1 {
			margin-bottom: 22px;
		}
		h2 {
			// line-height: 30px;
			color: $white;
			max-width: 740px;
			margin: 0 auto 30px;
		}
		.btn {
			color: $white;
			font-weight: bold;
		}
	}
	@include media-breakpoint-down(md) {
		min-height: 360px;
		&.higher {
			min-height: 540px;
		}
		///////////////////////////
		&.has-video {
			height: auto;
			.video-holder {
				display: none;
			}
			.banner-text {
				margin-top: 0;
			}
			.centered {
				padding: 0 8%;
				width: 100%;
			}
		}
		///////////////////////////
		&.investors-banner {
			min-height: 450px;
		}
		.banner-text {
			max-width: 682px;
			h2 {
				font-size: 18px;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		min-height: 277px;
		///////////////////////////
		&.has-video {
			// height: 540px;
			height: 465px;
			min-height: 465px;
			.video-holder {
				display: none;
			}
			.centered {
				left: 16px;
				max-width: 90%;
				padding: 0;
			}
		}
		.video-fallback {
			display: block;
			height: 560px;
			min-height: 560px;
		}
		///////////////////////////
		.banner-text {
			min-width: 100%;
			padding-left: 20px;
			padding-right: 20px;
			h2 {
				font-size: 14px;
				// line-height: 20px;
			}
		}
	}
}

.banner-overlay {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: $banner-overlay;
	opacity: .4;
}

.bkgr-gradient {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: linear-gradient(45deg, rgba($black, .8) 0%, rgba($black, .26) 80%, rgba($black, 0) 100%);
}

//----------  quote
//------------------------------------------------------------------------------
.quote-section {
	width: 112%;
	margin: 0 0 27px -12%;
	@include media-breakpoint-down(md) {
		width: 100%;
		margin: 0 0 10px;
		[class*='col-'] {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
.quote {
	border-top: 3px solid $yellow;
	padding-top: 32px;
	padding-left: 0;
	p {
		font-size: 20px;
		// line-height: 28px;
		color: $grey2;
		position: relative;
		margin-bottom: 20px;
		&::before {
			content: '"';
			position: absolute;
			top: -18px;
			left: -5px;
			color: $yellow;
			font-size: 40px;
			font-style: italic;
			font-family: $font-default;
			font-weight: 400;
		}
	}
	span {
		font-size: 14px;
		line-height: 17px;
		color: $grey;
		display: block;
		margin-bottom: 5px;
	}
	.name-title {
		font-weight: bold;
	}
	@include media-breakpoint-down(md) {
		border-top: 3px solid $yellow;
		padding-top: 35px;
		margin-top: 20px;
		margin-bottom: 20px;
		p {
			// line-height: 24px;
			margin-bottom: 13px;
			&::before {
				font-size: 50px;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		p::before {
			top: -16px;
		}
	}
}

//----------  image wide
//------------------------------------------------------------------------------
.image-wide {
	margin-bottom: 41px;
	img {
		width: 100%;
	}
	.img-bkgr {
		background-image: url('../img/content/img-2.png');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		min-height: 253px;
	}
	.mobile-img-bkgr {
		display: none;
		background-image: url('../img/content/mobile-img.png');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		min-height: 180px;
	}
	.show-on-desktop {
		display: block;
	}
	.show-on-mobile {
		display: none;
	}
	@include media-breakpoint-down(md) {
		margin-bottom: 28px;
		.img-bkgr {
			min-height: 170px;
		}
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 35px;
		.img-bkgr {
			display: none;
		}
		.mobile-img-bkgr {
			display: block;
		}
		.show-on-desktop {
			display: none;
		}
		.show-on-mobile {
			display: block;
		}
	}
}

//----------  table responsive and text swipe
//------------------------------------------------------------------------------
.table-responsive {
	@include base-float;
	overflow: auto;
	margin-bottom: 30px;
}
.swipe-text {
	font-size: 14px;
	position: relative;
	padding-left: 35px;
	margin-bottom: 10px;
	clear: both;
	&::before {
		content: '';
		background: url('../img/ico-swipe.svg') no-repeat;
		width: 27px;
		height: 20px;
		position: absolute;
		top: 0;
		left: 0;
	}
}

//----------  table
//------------------------------------------------------------------------------
table:not(.table-list) {
	@include base-float;
	margin-bottom: 10px;
	thead {
		border-bottom: 2px solid $white;
		background-color: $yellow;
		th, td {
			color: $grey;
			border-right: 2px solid $white;
			font-weight: bold;
			&:last-of-type {
				border-right-style: none;
			}
		}
	}
	tbody {
		background-color: $grey-bg;
		td {
			border-right: 2px solid $white;
			border-bottom: 2px solid $white;
			&:last-of-type {
				border-right-style: none;
			}
		}
	}
	th, td {
		padding: 14px 22px;
		font-size: 16px;
		text-align: left;
		// letter-spacing: .01em;
		// min-width: 188px;
	}
	@include media-breakpoint-down(md) {
		th, td {
			padding: 14px 5px;
		}
	}
}

//----------  key figures
//------------------------------------------------------------------------------
.key-figures {
	background-color: $grey-bg;
	padding: 50px 15px 56px;
	margin: 30px auto;
	text-align: center;
	h4 {
		margin-bottom: 20px;
	}
	span {
		font-size: 16px;
		line-height: 18px;
		color: $grey;
		text-transform: uppercase;
		display: block;
	}
	.fy {
		text-transform: none;
	}
	.helper-text p {
		font-size: 12px;
		line-height: 15px;
		color: #484848;
		display: block;
		text-transform: none;
		text-align: left;
	}
	.price {
		font-size: 55px;
		line-height: 60px;
		color: $blue;
		text-transform: none;
		margin-bottom: 23px;
		font-weight: bold;
	}
	&.white-bg {
		background-color: $white;
		padding: 0 15px;
		.highlights-wrapper {
			max-width: 840px;
			padding: 0 30px;
			margin: 0 auto;
		}
		.price {
			font-size: 35px;
			line-height: 40px;
			margin-bottom: 17px;
		}
		span {
			font-size: 12px;
			line-height: 15px;
		}
		.col-md-3 {
			margin-bottom: 31px;
		}
	}
	@include media-breakpoint-down(md) {
		padding-bottom: 66px;
		span {
			font-size: 14px;
		}
		.price {
			font-size: 45px;
			margin-bottom: 17px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding-bottom: 55px;
		h4 {
			margin-bottom: 26px;
		}
		.price {
			margin-bottom: 11px;
		}
		.col-md-4:not(:last-of-type) {
			margin-bottom: 43px;
		}
		&.white-bg {
			.highlights-wrapper {
				padding: 0 20px;
			}
		}
	}
}

//----------  accordion
//------------------------------------------------------------------------------
.accordion {
	@include base-float;
	margin-bottom: 30px;
	.item {
		@include base-float;
	}
	.accordion-head a,
	.accordion-content {
		font-size: 16px;
		line-height: 20px;
		text-align: left;
	}
	.accordion-head {
		@include base-float;
		background-color: $blue;
		position: relative;
		border-bottom: 2px solid $white;
		a {
			color: $white;
			@include base-float;
			padding: 18px 22px 12px;
			// letter-spacing: .03em;
			letter-spacing: 0;
			position: relative;
			font-weight: bold;
			&::after {
				content: $fa-var-chevron-circle-down;
				@include icon-font;
				font-size: 18px;
				color: $white;
				position: absolute;
				right: 22px;
			}
		}
		// &.open a::after {
		// 	content: $fa-var-chevron-circle-up;
		// }
		// &:hover a {
		// 	text-decoration: underline;
		// }
		&.open,
		&.open:active {
			background-color: $blue;
			a {
				color: $white;
				text-decoration: none;
				&::after {
					content: $fa-var-chevron-circle-up;
					color: $white;
				}
			}
		}
		&:hover {
			background-color: $grey;
			a {
				text-decoration: underline;
				color: $white;
				&::after {
					color: $white;
					text-decoration: none;
				}
			}
		}
		a::after {
			text-decoration: none;
		}
	}
	.accordion-content {
		@include base-float;
		padding: 22px 15px 14px;
		display: none;
		background-color: $grey-bg;
		border-bottom: 2px solid $white;
	}
	ul, ol {
		li {
			display: block;
			margin-bottom: 20px;
		}
	}
	@include media-breakpoint-down(lg) {
		.accordion-head {
			&:hover {
				background-color: $blue;
				a {
					text-decoration: none;
					color: $white;
					&::after {
						color: inherit;
					}
				}
			}
			&.open {
				background-color: $blue;
				a {
					color: $white;
				}
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.accordion-head a::after {
			font-size: 14px;
			right: 14px;
		}
		.accordion-content {
			padding: 22px 20px 8px;
		}
	}
}

//----------  tabs
//------------------------------------------------------------------------------
.tabs-container {
	@include base-float;
	margin-bottom: 30px;
}
.tabs {
	@include base-float;
	margin-bottom: 25px;
	border-bottom: 5px solid $yellow;
	padding-left: 0;
	li {
		float: left;
		display: inline-block;
		padding: 0;
		margin-right: 2px;
		&:last-of-type {
			margin-right: 0;
		}
	}
	@include media-breakpoint-down(md) {
		margin-bottom: 25px;
		li {
			max-width: 166px;
		}
	}
	@include media-breakpoint-down(sm) {
		display: none;
		li {
			margin-right: 0;
		}
	}
}

.tabs li, .tab-heading {
	background-color: $grey-bg;
	// border-bottom: 1px solid $white;
	&:hover {
		background-color: $grey;
		color: $white;
		a {
			color: $white;
		}
	}
	&.selected {
		position: relative;
		background-color: $yellow;
		// border-bottom: 1px solid $yellow;
		color: $grey;
		a {
			color: $grey;
		}
	}
	@include media-breakpoint-down(lg) {
		&:hover {
			background-color: $grey-bg;
			color: $grey;
			a {
				color: $grey;
				text-decoration: none;
				&::after {
					color: inherit;
				}
			}
		}
		&.selected {
			background-color: $yellow;
			color: $grey;
			a {
				color: $grey;
			}
		}
	}
}
.tabs a, .tab-heading {
	@include base-float;
	position: relative;
	font-size: 16px;
	line-height: 20px;
	text-align: left;
	padding: 18px 22px 11px;
	color: $grey;
	font-weight: bold;
	text-transform: uppercase;
	@include media-breakpoint-only(md) {
		padding: 18px 14px 11px;
	}
}
.tab-content {
	display: none;
	@include base-float;
	.inline-list a {
		text-decoration: none;
	}
	@include media-breakpoint-down(sm) {
		margin-top: 33px;
		padding-bottom: 11px;
	}
}
.tab-heading {
	display: none;
	@include media-breakpoint-down(sm) {
		display: block;
	}
}

//----------  pagination
//------------------------------------------------------------------------------
.pagination {
	@include base-float;
	text-align: center;
	position: relative;
	margin-bottom: 60px;
	li {
		display: inline-block;
		float: none;
	}
	a {
		font-size: 16px;
		color: $blue;
		padding: 5px 7px;
		font-weight: bold;
	}
	.active {
		color: $grey-light;
		position: relative;
		&::after {
			content: '';
			height: 2px;
			width: 100%;
			position: absolute;
			bottom: -9px;
			left: 0;
			display: block;
			background-color: $yellow;
		}
	}
	.prev a::before,
	.next a::before {
		@include icon-font;
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		color: $blue;
		font-size: 16px;
		padding: 10px;
	}
	.prev {
		margin-right: 44px;
		margin-left: -35px;
		a::before {
			content: $fa-var-chevron-circle-left;
		}
	}
	.next {
		margin-left: 7px;
		a::before {
			content: $fa-var-chevron-circle-right;
		}
	}
}

//----------  latest report box
//------------------------------------------------------------------------------
.latest-report-box-background {
	@include base-float;
	margin-bottom: 40px;
	position: relative;
	height: 280px;
	h2 {
		// line-height: 27px;
		// letter-spacing: -.02em;
		max-width: 52%;
		a {
			color: inherit;
			font-size: inherit;
			line-height: inherit;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	h4 {
		margin-bottom: 10px;
	}
	.download-link {
		font-size: 14px;
		line-height: 20px;
		font-weight: bold;
		color: $blue;
		text-transform: uppercase;
		&::before {
			color: $grey;
		}
	}
	.main-wrapp {
		height: 100%;
		overflow: hidden;
		//background-image: url('../img/content/img-ar.png');
		background-size: contain;
		background-position: right bottom;
		background-repeat: no-repeat;
	}
	.inner-content {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.top-content {
		height: 28px;
		display: flex;
		.left {
			width: 20%;
			background-color: $white;
			border-bottom: 1px solid $grey-light;
			border-right: 1px solid $grey-light;
			height: 100%;
			transform: skewX(-45deg);
			margin-right: 26px;
			&::before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: -15px;
				height: 100%;
				width: 50px;
				background-color: $white;
			}
		}
		.right {
			width: 80%;
			border-bottom: 1px solid $grey-light;
			border-left: 1px solid $grey-light;
			background-color: $white;
			transform: skewX(45deg);
			&::after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				right: -15px;
				height: 100%;
				width: 50px;
				background-color: $white;
			}
		}
	}
	.center-content {
		flex: 1 1 auto;
		border-left: 1px solid $grey-light;
		border-right: 1px solid $grey-light;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.report-box-top {
		padding: 18px 30px;
	}
	.report-box-bottom {
		margin-top: auto;
		padding: 18px 30px;
		background-color: $white;
		opacity: 0.7;
		border-top: 1px solid $grey-bg;
		&::after {
			content: '';
			background-color: $white;
			opacity: 0.9;
			display: block;
			position: absolute;
			width: 90%;
			height: 48px;
			left: 5px;
			z-index: -1;
		}
	}
	.bottom-content {
		display: flex;
		width: 100%;
		height: 28px;
		.left {
			background-color: $white;
			width: 80%;
			border-top: 1px solid $grey-light;
			border-right: 1px solid $grey-light;
			height: 100%;
			transform: skewX(45deg);
			margin-right: 26px;
			&::before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: -15px;
				height: 100%;
				width: 50px;
				background-color: $white;
			}
		}
		.right {
			background-color: $white;
			width: 20%;
			border-top: 1px solid $grey-light;
			border-left: 1px solid $grey-light;
			transform: skewX(-45deg);
			&::after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				right: -15px;
				height: 100%;
				width: 50px;
				background-color: $white;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 84px;
		height: 330px;
		h2 {
			max-width: 100%;
		}
	}
}
.latest-report-box-inner {
	border-right: 1px solid $grey;
	border-left: 1px solid $grey;
	.report-box-top {
		padding: 18px 30px;
		margin-bottom: 28px;
	}
	.report-box-bottom {
		padding: 18px 30px 40px;
		background-color: $white;
		opacity: .8;
		&::before {
			margin-right: 5px;
		}
	}
	.border-top,
	.border-bottom {
		position: relative;
		&::before, &::after {
			content: '';
			position: absolute;
			background-color: $grey;
			height: 1px;
			z-index: 2;
		}
		&::before {
			left: 0;
		}
		&::after {
			right: 0;
		}
	}
	.border-top::before,
	.border-bottom::after {
		width: 37px;
	}
	.border-top::after,
	.border-bottom::before {
		width: calc(100% - 74px);
	}
	.border-top {
		&::before, &::after {
			top: 0;
		}
	}
	.border-bottom {
		&::before, &::after {
			bottom: 0;
		}
	}
	.arrow-top,
	.arrow-bottom {
		border-top: 1px solid $grey;
		border-right: 1px solid $grey;
	}
	.arrow-top {
		left: 42px;
		top: -14px;
		transform: rotate(-45deg);
	}
	.arrow-bottom {
		bottom: -14px;
		right: 42px;
		transform: rotate(135deg);
	}
}

//----------  highlight boxes
//------------------------------------------------------------------------------
.highlight-boxes {
	.wrapper {
		max-width: 1140px;
		display: flex;
		padding: 0;
		// box-shadow: 0 6px 30px -2px rgba(0, 0, 0, 0.58);
	}
	.item {
		flex: 1;
		display: flex;
		flex-direction: column;
		min-height: 380px;
		background-repeat: no-repeat;
		background-position: bottom right;
		background-size: cover;
		span {
			font-size: 16px;
			font-weight: 700;
			padding: 25px 30px 0;
			margin-bottom: 13px;
			display: block;
			text-transform: uppercase;
		}
		h2 {
			font-size: 23px;
			// line-height: 27px;
			padding-left: 30px;
			margin-bottom: auto;
		}
		p {
			padding: 0 30px;
			// letter-spacing: -.02em;
			// line-height: 27px;
			margin-bottom: auto;
		}
		.wrapp-play {
			flex: 1;
			h3 {
				margin-top: 30px;
				margin-bottom: 30px;
				max-width: 170px;
			}
		}
		.bottom-link span {
			padding: 0;
		}
	}
	.bottom-link {
		font-size: 14px;
		padding: 15px 30px 23px 26px;
		text-transform: uppercase;
		font-weight: 700;
		position: relative;
		&::after {
			content: '';
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 1px;
			opacity: .5;
			background-color: $grey-bg;
		}
		a::before {
			color: $grey;
		}
		&:hover span {
			text-decoration: underline;
		}
		span {
			padding: 0;
			line-height: normal;
			font-size: 14px;
			margin-bottom: 0;
			display: inline-block;
		}
	}
	.white {
		background-color: $white;
		// background-size: contain;
		span {
			color: $blue;
		}
		h2 {
			max-width: 250px;
		}
		&.has-bg h2 {
			color: $grey;
			// letter-spacing: .03em;
		}
		&.no-bg h2 {
			color: $blue-dark;
			font-size: 23px;
			font-weight: bold;
			text-transform: uppercase;
			// letter-spacing: -.02em;
			margin-bottom: 23px;
		}
		.bottom-link {
			background: rgba(255, 255, 255, 0.7);
		}
		.download-link {
			color: $blue;
		}
	}
	.blue {
		background-color: $blue;
		// background-size: contain;
		span {
			color: $white;
		}
		h2 {
			color: $white;
			max-width: 310px;
		}
		.bottom-link {
			background: rgba(0, 176, 240, 0.7);
		}
		.next-link {
			color: $white;
		}
	}
	.black {
		background-color: $black;
		span {
			color: $yellow;
		}
		h2 {
			color: $white;
			// margin-bottom: 30px;
			max-width: 230px;
		}
		.bottom-link {
			background: rgba(251, 186, 0, 0.7);
		}
		.next-link {
			color: $white;
			span {
				color: $white;
			}
		}
		.play-btn {
			width: 62px;
			height: 62px;
			background: $yellow;
			border-radius: 50%;
			position: relative;
			font-size: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 0 37px 29px;
			box-shadow: 0 6px 30px -2px rgba(0, 0, 0, 0.58);
			&::after {
				content: $fa-var-play;
				@include icon-font;
				display: block;
				font-size: 30px;
				color: $white;
				position: absolute;
				@include align(absolute, xy);
				margin-left: 4px;
			}
		}
	}
	@include media-breakpoint-down(md) {
		margin-top: 0;
		.wrapper {
			flex-direction: column;
			padding: 0;
		}
		.item {
			min-height: 230px;
		}
		.white {
			h2 {
				max-width: 420px;
			}
			&.no-bg {
				padding-bottom: 23px;
				h2 {
					max-width: 100%;
					margin-bottom: 13px;
				}
				p {
					margin-bottom: 11px;
				}
			}
		}
		.blue h2 {
			max-width: 520px;
		}
		.black {
			// background-size: contain;
			h2 {
				max-width: 330px;
			}
		}
		.bottom-link {
			padding: 14px 30px 17px 22px;
		}
	}
	@include media-breakpoint-only(md) {
		.item {
			background-size: contain;
			margin-bottom: 10px;
		}
		.black {
			h2,
			.play-btn {
				float: left;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.item {
			min-height: 320px;
			span {
				padding: 22px 24px 0;
				margin-bottom: 6px;
			}
			h2 {
				font-size: 20px;
				// line-height: 25px;
				// letter-spacing: -.01em;
				padding-left: 24px;
				max-width: 210px;
			}
			p {
				padding: 0 30px;
			}
		}
		.white h2 {
			max-width: 220px;
		}
		.blue h2 {
			max-width: 260px;
		}
		.black h2 {
			max-width: 190px;
		}
	}
}

//----------  income box
//------------------------------------------------------------------------------
.income-box {
	padding: 25px 30px 32px;
	h4 {
		margin-bottom: 27px;
	}
	.income-amount {
		font-size: 46px;
		line-height: 48px;
		margin-bottom: 15px;
		color: $yellow;
	}
	.income-name {
		margin-bottom: -5px;
		font-size: 16px;
		color: $white;
		text-transform: uppercase;
	}
	.fy {
		font-size: 14px;
		color: $white;
	}
	@include media-breakpoint-down(md) {
		.income-amount,
		.income-name,
		.fy {
			display: block;
		}
	}
}

//----------  share price box
//------------------------------------------------------------------------------
.share-price-box {
	position: relative;
	display: flex;
	flex-direction: column;
	&::after, &::before {
		content: '';
		position: absolute;
		border-left: 18px solid transparent;
		border-right: 18px solid transparent;
	}
	&:first-of-type {
		&::after {
			top: -25px;
			right: 48px;
		}
		&::before {
			bottom: -25px;
			left: 48px;
		}
	}
	&:last-of-type {
		&::after {
			top: -25px;
			left: 48px;
		}
		&::before {
			bottom: -25px;
			right: 48px;
		}
	}
	h2 {
		font-size: 22px;
		// line-height: 30px;
		margin-bottom: 0;
	}
	&.yellow {
		.box-details,
		.income-box,
		.link-block,
		.overlay-bg {
			background-color: $yellow;
		}
		.no-uppercase {
			text-transform: none;
			margin-bottom: 20px;
		}
		.white {
			margin-bottom: 20px;
		}
		.box-details {
			padding-bottom: 25px;
		}
		.next-link {
			color: $white;
		}
		&::after {
			border-bottom: 25px solid $yellow;
		}
		&::before {
			border-top: 25px solid $yellow;
		}
		.price {
			font-size: 26px;
			color: $white;
			text-transform: uppercase;
			b {
				font-size: 46px;
				margin-right: 9px;
			}
		}
		.date {
			// letter-spacing: .06em;
			padding-left: 20px;
		}
		.percentage {
			color: $white;
			font-size: 24px;
		}
	}
	&.white {
		.box-details,
		.income-box,
		.link-block,
		.overlay-bg {
			background-color: $white;
		}
		h4 {
			margin-bottom: 28px;
		}
		.next-link {
			color: $blue;
		}
		&::after {
			border-bottom: 25px solid $white;
		}
		&::before {
			border-top: 25px solid $white;
		}
	}
	&.blue {
		.box-details,
		.income-box,
		.link-block,
		.overlay-bg {
			background-color: $blue-dark;
		}
		h4, span {
			color: $white;
			display: inline-block;
		}
		&::after {
			border-bottom: 25px solid $blue-dark;
		}
		&::before {
			border-top: 25px solid $blue-dark;
		}
		.next-link::before {
			color: $white;
		}
	}
	.box-details {
		padding: 25px 30px 10px;
		.date {
			text-transform: uppercase;
			font-weight: bold;
			font-size: 14px;
			margin-bottom: 12px;
		}
	}
	.link-block {
		@include base-float;
		padding: 18px 30px 24px 26px;
		border-top: 1px solid $grey-bg;
		margin-top: auto;
	}
	.next-link {
		font-size: 14px;
		text-transform: uppercase;
		font-weight: bold;
		// letter-spacing: .04em;
		&::before {
			color: $grey;
			top: 2px;
		}
	}
	.overlay-bg {
		position: relative;
		width: 100%;
		height: 100%;
	}
	@include media-breakpoint-down(md) {
		margin-bottom: 50px;
		&:first-of-type {
			&::after {
				left: 48px;
				right: auto;
			}
			&::before {
				right: 48px;
				left: auto;
			}
		}
		&.yellow {
			.col-md-6:not(:last-of-type) {
				margin-bottom: 18px;
			}
			.no-uppercase {
				margin-bottom: 0;
			}
			.box-details {
				padding-bottom: 16px;
			}
		}
		&.white {
			h4 {
				margin-bottom: 22px;
			}
			.col-md-6:not(:last-of-type) {
				margin-bottom: 23px;
			}
		}
	}
}

//----------  box arrow
//------------------------------------------------------------------------------
.box-arrow {
	width: 27px;
	height: 27px;
	position: absolute;
	display: inline-block;
}

//----------  section with text and images
//------------------------------------------------------------------------------
.text-with-image {
	width: 125%;
	.img-wrap {
		max-width: 80%;
		margin-top: 34px;
	}
	img {
		width: 100%;
	}
	@include media-breakpoint-down(md) {
		width: 100%;
		.img-wrap {
			max-width: 100%;
			margin-top: 8px;
		}
	}
}

.image-with-image {
	@include base-float;
	margin-bottom: 30px;
	.img-wrap {
		max-width: 100%;
	}
	img {
		width: 100%;
	}
	@include media-breakpoint-down(md) {
		.img-wrap {
			margin-bottom: 30px;
		}
	}
}

.date-wrap {
	margin-bottom: 30px;
}
