//----------  typography
//------------------------------------------------------------------------------

h1 {
	font-size: 60px;
	// line-height: 60px;
	text-transform: uppercase;
	font-weight: bold;
	color: $white;
	letter-spacing: -.03em;
	margin-bottom: 17px;
	&.no-uppercase {
		text-transform: none;
	}
	@include media-breakpoint-down(md) {
		font-size: 45px;
		// line-height: 45px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 30px;
		// line-height: 30px;
	}
}

h2 {
	font-size: 23px;
	// line-height: 27px;
	margin-bottom: 30px;
	color: $grey;
	letter-spacing: -.03em;
	&.intro {
		color: $blue;
	}
	@include media-breakpoint-down(md) {
		font-size: 20px;
		// line-height: 20px;
	}
}

h3 {
	font-size: 18px;
	// line-height: 20px;
	color: $blue;
	font-weight: bold;
	letter-spacing: -.03em;
}

h4 {
	font-size: 16px;
	// line-height: 18px;
	font-weight: bold;
	color: $grey;
	text-transform: uppercase;
	letter-spacing: -.03em;
	&.blue {
		color: $blue;
	}
	&.white {
		color: $white;
	}
}

h5 {
	font-size: 14px;
	// line-height: 14px;
	color: $grey;
	text-transform: uppercase;
	letter-spacing: -.03em;
}

p {
	font-size: 16px;
	// line-height: 18px;
	line-height: 24px;
	color: $grey;
	margin-bottom: 25px;
	letter-spacing: 0;
	a {
		font-size: inherit;
		color: $blue-dark;
		text-decoration: underline;
		letter-spacing: 0;
		&:hover {
			color: $blue;
		}
	}
}

// Icon font
[class*='font-'] {
	display: inline-block;
	line-height: 1;
}

.overflow-hidden {
	// overflow: hidden;
}

.download-link,
.next-link,
.external-link,
.desktop-link,
.calendar-link {
	display: block;
	color: $grey-light;
	font-size: 16px;
	position: relative;
	margin-left: 24px;
	&::before {
		@include icon-font;
		position: absolute;
		top: 5px;
		left: -22px;
		text-decoration: underline;
	}
	&::before,
	&:hover::before {
		color: $grey-light;
		text-decoration: none;
	}
}

.download-link,
.external-link,
.desktop-link,
.calendar-link {
	&::before {
		color: $yellow;
	}
}

.next-link::before {
	content: $fa-var-chevron-circle-right;
	color: $blue;
}

.external-link::before {
	content: $fa-var-external-link;
}

.desktop-link::before {
	content: $fa-var-desktop;
}

.download-link::before {
	content: $fa-var-download;
}

.calendar-link::before {
	content: $fa-var-calendar;
	margin-right: 15px;
}

.rte-content {
	@include base-float;
	ul,
	ol {
		list-style-position: inside;
		margin-bottom: 20px;
		padding-left: 30px;
		margin-top: 15px;
		li {
			font-size: 16px;
			color: $grey;
			padding-left: 26px;
			position: relative;
			margin-bottom: 4px;
			display: table;
			&::before {
				display: inline-block;
				position: absolute;
				left: 0;
			}
		}
		a {
			color: $blue-dark;
			&:hover {
				color: $blue;
			}
		}
		ul, ol {
			padding-left: 0;
			margin-top: 4px;
			margin-bottom: 0;
		}
	}
	ul li {
		line-height: 20px;
		&::before {
			content: $fa-var-circle;
			@include icon-font;
			color: $yellow;
			font-size: 6px;
			top: 8px;
		}
	}
	ol li {
		counter-increment: step-counter;
		counter-reset: none;
		&::before {
			content: counter(step-counter) '.';
			display: inline-block;
			color: $yellow;
		}
	}
	.countries {
		padding-left: 0;
		margin-top: 0;
		li {
			width: 100%;
			padding-left: 0;
			border-top: 0;
			&::before {
				display: none;
			}
			.selectric .button {
				top: 50%;
			}
			.selectric-wrapper {
				.selectric-items {
					top: 0;
				}
			}
			.btn {
				background-color: transparent;
				&:hover {
					background-color: $yellow;
				}
			}
		}
		ul {
			margin-top: 0;
			li {
				padding-left: 10px;
				color: $grey;
			}
		}
	}
	.inline-list,
	.tabs,
	.pagination,
	.news-list,
	.calendar-list {
		padding-left: 0;
		li {
			padding-left: 0;
			margin-bottom: 0;
			display: inline-block;
			&::before {
				display: none;
			}
		}
		a {
			color: $grey;
		}
		h2 a {
			color: inherit;
		}
	}
	.paragraph-line {
		@include base-float;
		border-bottom: 3px solid $orange;
		margin-bottom: 25px;
	}
	.tabs,
	.accordion {
		img {
			width: 100%;
		}
	}
	@include media-breakpoint-down(md) {
		ul li, ol li {
			padding-left: 10px;
			.selectric {
				width: 100%;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.countries {
			li .selectric {
				width: 100%;
			}
			.selectric-items ul {
				margin-bottom: 0;
			}
		}
	}
}
